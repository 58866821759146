<template>
	<v-container fluid class="px-10">
		<v-row>
			<v-col cols="12">
				<h1 color="white">Chat Commands</h1>
				<v-divider class="my-5"></v-divider>
			</v-col>

			<v-col cols="12">
				<v-row justify="space-between">
					<v-col sm="12" md="12" lg="6" xl="6" cols="12">
						<h3>Standard Commands</h3>
						<v-card flat tile :loading="loadingCommands">
							<v-simple-table>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">Command</th>
											<th class="text-left mgn_desc">Description</th>
											<th class="text-left mgn_desc">Minimum Chat Role</th>
										</tr>
									</thead>
									
									<tbody>
										<tr v-for="(cmd, i) in standards" :key="i">
											<td>{{ cmd.command }}</td>
											<td>{{ cmd.desc }}</td>
											<td>{{ cmd.role }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card>
					</v-col>

					<v-divider vertical v-if="vertical()"></v-divider>

					<v-col sm="12" md="12" lg="6" xl="6" cols="12">
						<h3>Text Commands</h3>
						<v-card flat tile :loading="loadingCommands">
							<v-simple-table>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">Command</th>
										</tr>
									</thead>
									
									<tbody>
										<tr v-for="(cmd, i) in commands" :key="i">
											<td>{{ cmd }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card>
					</v-col>

				</v-row>
			</v-col>

		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			loadingCommands: false,
			standards: [],
			commands: []		
		}
	},

	methods: {
		vertical () {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return false
				case 'sm': return false
				case 'md': return false
				case 'lg': return true
				case 'xl': return true
			}
		}
	},

	async mounted() {
		this.loadingCommands = true;
		try {
			const request = await fetch(this.$store.state.base_url + '/channel/commands', {
				method: 'GET',
				headers: {
					"Authorization" : this.$cookies.get('mgn_sessid'),
				}
			});
			const res = await request.json();

			if (request.ok && request.status === 200) {
				if (res.commands) {
					this.commands = res.commands;
					this.standards = res.standards;
				}
			}
			else {
				if (res.error) {
					if (this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
					}
					else {
						throw res.error;
					}
				}
			}
		} 
		catch (error) {
			this.$notify({
				group: 'main',
				type: 'error',
				title: this.$store.state.app_name,
				text: error.message
			});
		}
		finally {
			this.loadingCommands = false;
		}
	},
}
</script>